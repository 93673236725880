<template>
    <b-modal
        id="modal-fund"
        cancel-variant="outline-secondary"
        ok-title="Login"
        cancel-title="Close"
        title="Add Funds"
        hide-footer
    >   <b-overlay
            :show="loading"
            rounded="xl"
            :variant="variant"
            :opacity="opacity"
            :blur="blur"
            >
            <validation-observer ref="fundRules">
                <b-form>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group 
                                label="Method"
                                label-for="h-first-name"
                                label-cols-md="4"
                            >
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="HomeIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input
                                        
                                        type="text"
                                        placeholder="Email Address"
                                        disabled
                                        :value="banksConfig[bank].name"
                                    />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group 
                                label="Account"
                                label-for="h-first-name"
                                label-cols-md="4"
                            >
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input
                                        
                                        type="text"
                                        placeholder="Email Address"
                                        disabled
                                        :value="banksConfig[bank].account"
                                    />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group 
                                :label="banksConfig[bank].name == 'Paypal' ? 'Email':'Number'"
                                label-for="h-first-name"
                                label-cols-md="4"
                            >
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text>
                                        <feather-icon :icon="banksConfig[bank].name == 'Paypal' ? 'MailIcon':'CreditCardIcon'" />
                                    </b-input-group-prepend>
                                    <b-form-input
                                        
                                        type="text"
                                        placeholder="Email Address"
                                        disabled
                                        :value="banksConfig[bank].number"
                                    />
                                    <b-input-group-append>
                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="success"
                                            class="btn-icon"
                                            v-clipboard:copy="banksConfig[bank].number"
                                            v-clipboard:success="onCopy"
                                            v-clipboard:error="onError"
                                            >
                                            <feather-icon
                                                icon="CopyIcon"
                                                class="mr-50"
                                            />
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group 
                                label="Address"
                                label-for="h-first-name"
                                label-cols-md="4"
                            >
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input
                                        
                                        type="text"
                                        placeholder="Email Address"
                                        disabled
                                        :value="banksConfig[bank].address"
                                    />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <br>
                        <b-col cols="12">
                            <b-form-group 
                                label="Amount (VND)"
                                label-for="h-first-name"
                                label-cols-md="4"
                            >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|integer"
                                name="Number"
                                >
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="DollarSignIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input
                                        
                                        type="number"
                                        placeholder="Amount"
                                        v-model="amount"
                                    />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group 
                                label="Exchange Rate"
                                label-for="h-first-name"
                                label-cols-md="4"
                            >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="Number"
                                >
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="DollarSignIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input
                                        type="text"
                                        placeholder="Amount"
                                        v-model="exchange"
                                        disabled
                                    />
                                </b-input-group>
                                <p class="text-info mt-1">You will get: {{Number(exchange_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}</p>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>
                        
                        <b-col cols="12" v-if="show_code">
                            <b-form-group 
                                label="Code"
                                label-for="h-first-name"
                                label-cols-md="4"
                            >
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="CodeIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input
                                        
                                        type="text"
                                        placeholder="Email Address"
                                        disabled
                                        v-model="code"
                                    />
                                    <b-input-group-append>
                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="success"
                                            class="btn-icon"
                                            v-clipboard:copy="code"
                                            v-clipboard:success="onCopy"
                                            v-clipboard:error="onError"
                                            >
                                            <feather-icon
                                                icon="CopyIcon"
                                                class="mr-50"
                                            />
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <p class="text-warning">Send payment with above code</p>
                            </b-form-group>
                        </b-col>
                        <hr>
                        <b-col cols="6" class="mt-4">
                            <b-button
                                variant="flat-primary"
                                size="xs"
                                @click="closeModal"
                                >
                                Close
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="mt-4">
                            <div class="text-right">
                            <b-button
                                variant="primary"
                                @click="createFunds"
                                size="xs"
                                class="text-right"
                                :disabled="hidden"
                            >
                            <span>Create</span>
                            </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            </b-overlay>
    </b-modal>
</template>

<script>
import { 
    BButton, BModal, VBModal, BCardText, BFormGroup, BRow, BForm, BCol, BCard,BFormInput, BOverlay,
    BInputGroupPrepend, BInputGroup, BInputGroupAppend
    } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
const UsersPaymentRepository = RepositoryFactory.get('userPayment')

export default {
    props:{
        bank: {
            type: Number,
            default: ()=>0
        },
    },
    components: {
        BButton,
        BModal,
        BCardText,
        BFormGroup,
        BRow,
        BForm,
        BCol,
        BCard,
        BFormInput,
        BInputGroupPrepend,
        BInputGroup,
        ValidationProvider,
        ValidationObserver,
        BInputGroupAppend,
        BOverlay
    },
    data() {
        return {
            show_code: false,
            loading: false,
            variant: 'dark',
            opacity: 0.85,
            blur: '2px',
            code: '',
            hidden: false,
            amount: '0',
            settings: this.$store.getters['auth/settings'],
            exchange: "",
            banksConfig:{
                0:{
                name: "Vietcombank",
                account:'Nguyen Trung Long',
                number: '0161001597083',
                address: 'Chi Nhanh Hue'
                },
                1:{
                name: "Dong A",
                account:'Nguyen Trung Long',
                number: '0161001597083',
                address: 'Chi Nhanh Hue'
                },
                2:{
                name: "Paypal",
                account:'Nguyen Trung Long',
                number: 'rani@qcodes.net',
                address: 'Chi Nhanh Hue'
                }
            },
        }
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    created(){
        this.settings = this.$store.getters['auth/settings']
        this.exchange = `1$ = ${this.settings.dollar_to_vnd} VND`
    },
    computed: {
        exchange_amount(){
            return this.amount/parseInt(this.settings.dollar_to_vnd)
        }
    },
    methods:{
        test(){
        },
        createFunds(){
            this.$refs.fundRules.validate().then(success => {
                if (success) {
                    this.loading = true
                    UsersPaymentRepository.createPayment({
                        method: this.bank,
                        amount: this.amount,
                    }).then(rs => {
                        this.code = rs.data.data._id,
                        this.$parent.updateFundList(rs.data.data)
                        this.show_code = true
                        this.loading = false
                        this.hidden = true
                    }).catch(e => {

                    })
                }
            })
        },
        showModal(){
            this.amount = 0
            this.show_code = false
            this.code = ''
            this.exchange = `1$ = ${Number(this.settings.dollar_to_vnd).toLocaleString('en-US', { style: 'currency', currency: 'VND' })}`
            this.$bvModal.show('modal-fund')
        },
        closeModal(){
            this.$bvModal.hide('modal-fund')
        },
        onCopy: function (e) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Text copied',
                    icon: 'BellIcon',
                },
            })
        },
        onError: function (e) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Can not copy!',
                    icon: 'BellIcon',
                },
            })
        },
    }
}
</script>